<template>
  <card :title="$t('Управления ручным маппингом компаний')">
    <load-block :load="load"></load-block>
    <div class="card-body">
      <div class="row">
        <SelectorBlock
          classElement="col-3"
          labelText="Организация"
          url="/RefEntity/DropDownList/Counterparties"

          @selectCode="setOrgId"
          labelSelect="entityName"
          :form="loadData"
          nameFiels="organizationId"
          v-if="showOrg"

          :use-custom-function-search="true"
          :customFunctionSearch="customSearhc"
        />

        <BaseInput
          v-if="configCorteos"
          :config="configCorteos"
          :form="loadData"
          :readonly="false"
        />
        <BaseInput
          v-if="configCorteosContractId"
          :config="configCorteosContractId"
          :form="loadData"
          :readonly="false"
        />
        <BaseInput
          v-if="configсorteosGroupId"
          :config="configсorteosGroupId"
          :form="loadData"
          :readonly="false"
        />


        <div class="col-4" v-if="!isModeEdit">
          <label>&nbsp;</label>
          <l-button
            type="success"
            wide
            block
            @click="createData"
            :disabled="!hasSave"

          >{{ $t("Добавить") }}
          </l-button>
        </div>
        <div class="col-2" v-if="isModeEdit">
          <label>&nbsp;</label>
          <l-button
            v-if="isModeEdit"
            type="warning"
            wide
            block
            @click="editData"
            :disabled="!hasSave"
          >{{ $t("Сохранить") }}
          </l-button>
        </div>
        <div class="col-2" v-if="isModeEdit">
          <label>&nbsp;</label>
          <l-button
            type="warning"
            wide
            block
            @click="clear"
            :disabled="!hasSave"
          >{{ $t("Отмена") }}
          </l-button>
        </div>

        <div class="col-12">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column property="id" label="id" width="100">
            </el-table-column>
            <el-table-column property="organizationId" label="ID организации">
            </el-table-column>
            <el-table-column property="organizationName" label="Название">
            </el-table-column>
            <el-table-column property="corteosContractId" label="ID договора Кортеос">
            </el-table-column>
            <el-table-column property="corteosOrganizationId" label="ID организации Кортеос">
            </el-table-column>
            <el-table-column property="corteosGroupId" label="corteosGroupId">
            </el-table-column>


            <el-table-column label="Действия">
              <template #default="scope">
                <l-button size="small" class=" mr-2" type="warning" @click="handleEdit(scope.row)">
                  Редактировать
                </l-button>

                <l-button
                  size="small"
                  type="danger"
                  class=" mr-2"
                  @click="beforeDelete(scope.row)"
                >
                  Удалить
                </l-button>
              </template>
            </el-table-column>
          </el-table>

        </div>
      </div>

    </div>
  </card>
</template>

<script>
import {API_METHODS, TYPE_ERRORS} from "../../../constant";
import TableControl from "./TableControl";
import {LOAD_ROLE_USER, UPDATE_ROLE_USER} from "./store/type";
import LoadBlock from "../../shared/LoadBlock";
import notify from "../../../helpers/notify/notify";
import {NAME_ACTION, NAME_OBJECT} from "../../../RoleConstanans";
import SelectorBlock from "../../v2/module/person/prefData/SelectorBlock.vue";
import BaseInput from "../generator/BaseComponets/BaseInput.vue";
import {ApiService} from "../../../util/ApiService";
import {Table, TableColumn} from "element-ui";
import swal from "sweetalert2";

export default {
  name: "AccessControls",
  components: {
    BaseInput,
    SelectorBlock,
    TableControl,
    LoadBlock,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      showOrg: true,
      loadData2: {
        contract: ''
      },
      loadData: {
        id: 0,
        organizationId: 0,
        corteosOrganizationId: 0,
        corteosContractId: 0,
        corteosGroupId: 0
      },
      show: false,
      option: [],
      load: false,
      configCorteos: null,
      configCorteosContractId: null,
      configсorteosGroupId: null,
      contractConfig: null,
      tableData: [],
      isModeEdit: false,

      memoryEdit: {
        object: {
          entityId: 0,
          entityName: ''
        }
      }
    };
  },
  computed: {
    userRoleLouded() {
      return this.$store.getters.userRoleLouded;
    },
    hasRead() {
      return (
        this.userRoleLouded &&
        this.isActionAccess(NAME_OBJECT.Admin, NAME_ACTION.isRead)
      );
    },
    hasSave() {
      return (
        this.loadData.organizationId &&
        this.loadData.corteosOrganizationId &&
        this.loadData.corteosContractId &&
        this.loadData.corteosGroupId &&
        this.userRoleLouded &&
        this.isActionAccess(NAME_OBJECT.Admin, NAME_ACTION.isEdit)
      );
    },
    tableData() {
      return this.$store.state.setting.dataRole;
    },
  },
  created() {
    this.configCorteos =
      this.getCustomConfigForInput('corteosOrganizationId', 'corteosOrganizationId', 'ID организации Кортеос')
    this.configCorteosContractId =
      this.getCustomConfigForInput('corteosContractId', 'corteosContractId', 'ID договора Кортеос')
    this.configсorteosGroupId =
      this.getCustomConfigForInput('corteosGroupId', 'corteosGroupId', 'corteosGroupId')
    this.loadindDataAll()
   this.contractConfig =
     this.getCustomConfigForInput('contract', 'contract', 'contract', 12)
  },
  methods: {
    getCustomConfigForInput(name = '', fieldName = '', label = '', col = 3) {
      return {
        type: "INPUT",
        description: "Текстовое поле",
        main: false,
        default: null,
        colsXS: col,
        colsSM: col,
        colsMD: col,
        colsLG: col,
        OffsetXS: 0,
        OffsetSM: 0,
        OffsetMD: 0,
        OffsetLG: 0,
        addClass: "",
        name: name,
        fieldName: fieldName,
        label: label,
        placeholder: null,
        validationRule: null,
        errName: null,
        cyData: null,
        enable: true,
        readonly: false,
        showTable: true,
        disableInCreate: false,
        relative: null,
        SearchType: "String",
        subListId: 0,
        isReadOnlyAdd: false,
        isReadOnlyEdit: false,
        watchAttr: null,
      };
    },
    setOrgId(val, isModeInit = false) {
      this.loadData.organizationId = val.entityId;
    },
    isActionAccess(name, actions) {
      return this.$store.getters.isActionAccess(name, actions);
    },

    async createData() {
      const {organizationId, corteosOrganizationId, corteosContractId, corteosGroupId} = this.loadData;
      try {
        const params = {
          self: this,
          saveData: {
            organizationId: Number(organizationId),
            corteosOrganizationId: Number(corteosOrganizationId),
            corteosContractId: Number(corteosContractId),
            corteosGroupId: Number(corteosGroupId),
          },
          query: {
            lang: this.$store.state.common.lang
          }
        };
        this.load = true;
        const res = await ApiService(API_METHODS.CREATE_SYNC_CORTEOS_ORG, params);
        this.showNotification(res)
        this.load = false;
        this.clear()
        await this.loadindDataAll()
      } catch (err) {
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
        this.load = false;
      }
    },

    async editData() {
      const {id, organizationId, corteosOrganizationId, corteosContractId, corteosGroupId} = this.loadData;
      try {
        const params = {
          self: this,
          saveData: {
            id: Number(id),
            organizationId: Number(organizationId),
            corteosOrganizationId: Number(corteosOrganizationId),
            corteosContractId: Number(corteosContractId),
            corteosGroupId: Number(corteosGroupId),
          },
          query: {
            lang: this.$store.state.common.lang
          }
        };
        this.load = true;
        const res = await ApiService(API_METHODS.UPDATE_SYNC_CORTEOS_ORG, params);
        this.showNotification(res)
        this.load = false;
        this.clear()
        await this.loadindDataAll()
      } catch (err) {
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
        this.load = false;
      }
    },

    showNotification(res) {
      if (res.isValid)
        notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
      else
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
    },

    clear() {
      this.loadData.corteosOrganizationId = 0
      this.loadData.corteosContractId = 0
      this.isModeEdit = false
      this.loadData.id = 0;
      this.loadData.organizationId = 0
      this.loadData.corteosGroupId = 0
      this.showOrg = false;
      this.$nextTick(() => {
        this.showOrg = true
      })
    },

    beforeDelete(row) {
      swal({
        title: this.$t("Вы уверены?"),
        text: this.$t("Вы не сможете вернуть это!"),
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("Да удалить!"),
        cancelButtonText: this.$t("Закрыть"),
        buttonsStyling: false
      }).then(res => {
        if (res.value && res.value == true) {
          this.deleteSync(row);
        }
      });
    },

    async deleteSync(row) {
      const {id} = row
      try {
        const params = {
          self: this,
          id,
          lang: this.$store.state.common.lang
        };
        this.load = true;
        await ApiService(API_METHODS.DELETE_SYNC_CORTEOS_ORG, params);
        notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
        this.load = false;
        await this.loadindDataAll()
      } catch (err) {
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
        this.load = false;
      }
    },

    async loadindDataAll() {
      try {
        const params = {
          self: this,
          lang: this.$store.state.common.lang
        };
        this.load = true;
        const res = await ApiService(API_METHODS.GET_SYNC_ALL_CORTEOS_ORG, params);
        this.load = false;
        this.tableData = res.object;
      } catch (err) {
        this.load = false;
      }
    },

    async customSearhc(search = null, id = null) {
      try {
        const params = {
          self: this,
          search: search ? search : '',
          id,
          lang: this.$store.state.common.lang,
        };
        this.load = true;
        let res
        if (id) {
          res = this.memoryEdit;
        } else {
          res = await ApiService(API_METHODS.CUSTOM_SEARHCH_FOR_SYNC_CORTEOS_ORG, params);
          res.object = res.object ? res.object.map(item => {
            return {
              entityCode: null,
              entityId: item.id,
              entityName: item.name,
              languageId: 0
            }
          }) : []
        }
        this.load = false;
        return res
      } catch (err) {
        this.load = false;
      }
      return [];
    },

    handleEdit(row) {
      const {id, organizationId, organizationName, corteosOrganizationId, corteosContractId, corteosGroupId} = row;
      this.memoryEdit.object.entityId = organizationId;
      this.memoryEdit.object.entityName = organizationName;

      this.loadData.organizationId = organizationId;
      this.loadData.corteosOrganizationId = corteosOrganizationId;
      this.loadData.id = id;
      this.loadData.corteosContractId = corteosContractId;
      this.loadData.corteosGroupId = corteosGroupId;


      this.isModeEdit = true


      this.showOrg = false;
      this.$nextTick(() => {
        this.showOrg = true
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.control {
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  margin: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
</style>
