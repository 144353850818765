<template>
  <div>
    <show-code :code="fieldInfoList"></show-code>
    <load-block :load="!load"></load-block>
    <ImportCommon />
  </div>
</template>
<script>


import clearReactive from "../../../helpers/clearReactive";
import ShowCode from "../../shared/ShowCode.vue";
import {mapState} from 'vuex';
import LoadBlock from "../../shared/LoadBlock.vue";
import ImportCommon from "./ImportCommon.vue"
export default {
  name: "PageAccess",
  components: {
    LoadBlock,
    ShowCode,
    ImportCommon
  },
  data() {
    return {
      fieldInfoList: null,
      preResult: null,
      load: true,
    };
  },
  computed: {
    ...mapState({
      lang: state => state.common.lang
    })
  },
  watch: {
    lang() {
      this.initData();
    }
  },
  created() {
    this.initData();
  },
  methods: {

    initData() {

    },
    saveData() {

    }
  }
};
</script>
